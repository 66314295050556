import React from 'react';
import '../css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class MenuNav extends React.Component {



    render(): React.ReactNode {
        return (
            <div className="bg-gradient row container-xxl-12">
                <div>
                    <Navbar collapseOnSelect className="navbar justify-content-center" expand="false">
                        <div>
                            <Navbar.Toggle data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </Navbar.Toggle>
                        </div>

                        <Navbar.Collapse id="navbarToggleExternalContent" className="justify-content-center">

                            <Nav>
                                <div className="p-4 d-flex row justify-content-center align-items-center h4 navitems">
                                    <Nav.Link as={Link} to="./Presentacion" className="col-md-1 text-center text-secondary my-3 mx-5 navItems" eventKey="1">Presentaci&oacute;n</Nav.Link>
                                    <Nav.Link as={Link} to="./FormacionAcademica" className="col-md-1 text-center text-secondary my-3 mx-5 navItems" eventKey="2">Formaci&oacute;n acad&eacute;mica</Nav.Link>
                                    <Nav.Link as={Link} to="./ExperienciaLaboral" className="col-md-1 text-center text-secondary my-3 mx-5 navItems" eventKey="3">Experiencia laboral</Nav.Link>
                                    <Nav.Link as={Link} to="./Referencias" className="col-md-1 text-center text-secondary my-3 mx-5 navItems" eventKey="4">Referencias</Nav.Link>
                                    <Nav.Link as={Link} to="./OtrosDatos" className="col-md-1 text-center text-secondary my-3 mx-5 navItems" eventKey="5">Otros datos de inter&eacute;s</Nav.Link>
                                    <Nav.Link as={Link} to="./Documentos" className="col-md-1 text-center text-secondary my-3 mx-5 navItems" eventKey="6">Documentos</Nav.Link>
                                    <Nav.Link as={Link} to="./Contacto" className="col-md-1 text-center text-secondary my-3 mx-5 navItems" eventKey="7">Contacto</Nav.Link>
                                </div>
                            </Nav>

                        </Navbar.Collapse>

                    </Navbar>
                </div>
            </div>
        );
    }
}

export default MenuNav;