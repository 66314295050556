import React from 'react';
import '../css/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

class ExperienciaLaboral extends React.Component {

  render(): React.ReactNode {
    return (
      <div className="bg-imagen bg-gradient row container-xxl-12">
        <div className="mx-2">

          <h2 className="text-secondary display-4 text-left pt-5 pb-3 ps-5 me-3">Experiencia Laboral</h2>

          <h6 className="fst-italic presentacionTexto text-left h4 p-5">
            A continuaci&oacute;n pueden ver mi actividad laboral.
          </h6>
          <p className="h4 text-left px-5">Como se puede comprobar, dicha actividad laboral no corresponde con la rama del desarrollo web. Es por esto que en 2021 hice un cambio
            de rumbo total y curs&eacute; los estudios referidos en Formaci&oacute;n Acad&eacute;mica, siendo esta la ruta laboral que deseo seguir. Es un mundo que me apasiona y
            del que siempre he querido formar parte, as&iacute; que espero poder hacerlo realidad en su empresa.
          </p>

          <div className="row justify-content-center m-5">

          <div className='col-sm-5 mb-5 mx-3 py-3'>
              <h4 className=" h2 bold mb-3">NTT DATA Spain, S.L.U.</h4>
              <p className="h4 ">Desde: </p> <p className="h4">27/09/2022 </p> <p className="h3">Hasta: </p> <p className="h4">En la actualidad</p>
              <p className="h3 ">Descripci&oacute;n del puesto: </p>
              <p className="h4">
              Solutions Assistant.
              </p>
              <p className="h4"> Actualmente en proyecto para el Ministerio de Justicia como FullStack.
              </p>
            </div>
            <div className='col-sm-5 mb-5 mb-5 mx-3 py-3'>
              <h4 className="h2 bold mb-3">Promoparc Subterr&aacute;neo Servicios</h4>
              <p className="h3">Desde: </p> <p className="h4">23/06/2014 </p> <p className="h3">Hasta: </p> <p className="h4">15/04/2021 </p>
              <p className="h3">Descripci&oacute;n del puesto: </p>
              <p className="h4">
                Desempe&ntilde;ando la labor de encargado de parking.
              </p>
              <p className="h4"> Atendiendo todas las tareas de gestión y mantenimiento
                y todo tipo de servicio que los parking de Madrid necesitaran.
              </p>
            </div>

            <div className="w-100"></div>

            <div className='col-sm-5 mb-5 mx-3 py-3'>
              <h4 className=" h2 bold mb-3">BLANCO BUREO ADMINISTRACI&Oacute;N 21,S.A.</h4>
              <p className="h4 ">Desde: </p> <p className="h4">03/02/2005 </p> <p className="h3">Hasta: </p> <p className="h4">23/06/2009</p>
              <p className="h3 ">Descripci&oacute;n del puesto: </p>
              <p className="h4">
                Mozo de carpinter&iacute;a y montaje de decorados para RTVE.
              </p>
            </div>
            <div className='col-sm-5 mb-5 mx-3 py-3'>
              <h4 className=" h2 bold mb-3">DIANA PROMOCI&Oacute;N, S.A.</h4>
              <p className="h3">Desde: </p> <p className="h4">14/06/2003</p> <p className="h3">Hasta: </p> <p className="h4">06/06/2004</p>
              <p className="h3">Descripci&oacute;n del puesto: </p>
              <p className="h4">
                Mozo de almac&eacute;n, cajero-reponedor en perfumer&iacute;a.
              </p>
            </div>
          </div>
          <p className="h4 text-left px-5">
            Aqu&iacute; se muestran los empleos m&aacute;s significativos. Obviando primeros empleos y otros de corta duraci&oacute;n. As&iacute; como el tiempo comprendido entre 2009 y 2014
            el cual estuve laboralmente inactivo debido a la operaci&oacute;n de la cual deriv&oacute; mi minusval&iacute;a y su correspondiente recuperaci&oacute;n y rehabilitaci&oacute;n hasta estar
            completamente recuperado para reanudar la actividad laboral.
          </p>
          <p className="h4 text-left px-5">
            Estaría encatado de ampliar esta informac&iacute;n en una entrevista personal.
          </p>
        </div>
      </div>
    );
  }
}

export default ExperienciaLaboral;