import React from 'react';
import fotoPedro from "../multimedia/fotoPedro.png";
import '../css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class Cabecera extends React.Component {

  render(): React.ReactNode {
    return (
      <div className="bg-gradient row container-xxl-12">
        <div className="my-5">
          <div className="container-fluid row">
            <div className="container-fluid row justify-content-center">
              <div className="col-xl-8" >
                <h1 className="text-secondary display-3 text-left  pt-5 pb-3 ps-5">Pedro Cuenca Panadero </h1>
                <h2 className="fst-italic display-6 text-left   ps-5">Desarrollador Full Stack</h2>
              </div>
              <div className="col-xl-4  d-flex justify-content-center">
                <img src={fotoPedro} className="rounded-circle border border-4 border-secondary img-fluid float-center mt-4 mb-5"
                  alt="foto cara Pedro Cuenca Panadero" width={300} height={300} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cabecera;