import React from 'react';
import '../css/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import ModalCurriculum from './ModalCurriculum';
import ModalVideoCurriculum from './ModalVideoCurriculum';
import ModalDiplomas from './ModalDiplomas';

class Documentos extends React.Component {


  render(): React.ReactNode {
    return (
      <div className="bg-gradient row container-xxl-12">

        <h2 className="text-secondary display-4 text-left pt-5 pb-3 ps-5 me-3 mb-5">Documentos</h2>

        <div className="row align-items-center justify-content-center">
          <div className="col-4">
            <h3 className="h2 text-secondary">Curr&iacute;culum Pdf</h3>
          </div>
          <div id="curriculum" className="col-2">

            <ModalCurriculum />

          </div>
        </div>

        <div className="row mt-5 align-items-center justify-content-center">
          <div className="col-4">
            <h3 className="h2 text-secondary">Video Curr&iacute;culum</h3>
          </div>
          <div id="videoCurriculum" className="col-2">

            <ModalVideoCurriculum />

          </div>
        </div>

        <div className="row mt-5 align-items-center justify-content-center">
          <div className="col-4">
            <h3 className="h2 text-secondary">Diplomas Curso Full Stack Pdf</h3>
          </div>
          <div id="diploma" className="col-2">

            <ModalDiplomas />

          </div>
        </div>
      </div>
    );
  }
}

export default Documentos;