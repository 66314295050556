import React from "react";
import { Button, Modal } from "react-bootstrap";
import ImagenBotonVideo from "../multimedia/video.png";


class ModalVideoCurriculum extends React.Component {

    static imagen: any;
    static body: any;
    static titulo: any;
    static id: any;
    show: boolean;

    constructor(props: any) {
        super(props);
        this.show = false;
    }

    handleClose = () => {
        this.show = false;
        this.setState({ show: false });
    };

    handleShow = () => {
        this.show = true;
        this.setState({ show: true });
    };

    render(): React.ReactNode {
        return (
            <div>

                <Button
                    onClick={this.handleShow}
                    variant="btn-primary"
                >
                    <img src={ImagenBotonVideo}
                        alt="boton abrir pdf" width={60} height={70} />
                </Button>

                <Modal size="lg" show={this.show} onHide={this.handleClose} animation={true}>
                    <Modal.Header closeButton className='bg-gradient'>
                        <Modal.Title><h3 className="h1 ms-5">Video Curr&iacute;culum</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-gradient'>
                        <div className="video-responsive">
                            <iframe className='ms-2 me-2'
                                width="750" height="400" src="https://www.youtube-nocookie.com/embed/lwZ2yGDT7vo" title="YouTube video player"
                                frameBorder="0" allow="accelerometer; autoplay=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalVideoCurriculum;