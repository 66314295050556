import React from 'react';
import { ProgressBar } from 'react-bootstrap';

class OtrosDatosInteres extends React.Component {

  render(): React.ReactNode {
    return (

      <div className="bg-imagen bg-gradient container-xxl-12">

        <h2 className="text-secondary display-4 text-left pt-5 pb-3 ps-5 me-3">Datos de Inter&eacute;s y Habilidades</h2>

        <div className="row justify-content-center m-5">

          <div className='col-sm-5 mb-5 mx-3 py-3'>

            <ul className="mt-2 fst-italic text-left p-5 h4">
              <li>Carnet de Conducir Tipo B</li>
              <li>Certificado de Discapacidad 33&#37;</li>
              <li>Ingl&eacute;s nivel medio comprensión en conversación y escrito</li>
              <li>Práctica de deportes</li>
              <li>Acogida y cria de diferentes especies animales</li>
            </ul>

          </div>

          <div className='col-sm-5 mb-5 mx-3 py-3'>
            <h4 className="h2 bold display-5 text-secondary text-center">Identidad Digital</h4>

            <ul className="mt-2 fst-italic text-center p-5 h4">
              <li>
                <a target="_blank" rel="noreferrer"
                  href="https://blog.fundaciononce.es/articulo/2021-12-09/mi-oportunidad-para-emprender-un-nuevo-camino"> Publicaci&oacute;n</a> en Blog para
                <a target="_blank" rel="noreferrer"
                  href='https://www.fundaciononce.es/es'> Fundaci&oacute;n ONCE</a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href="https://portalentodigital.fundaciononce.es/cursos/desarrolladora-full-stack-java-ed-ii">Imagen</a> para carteler&iacute;a
                de <a target="_blank" rel="noreferrer" href="https://portalentodigital.fundaciononce.es/"> XTalento Digital </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href="https://youtu.be/XcMjZ1w0kyg">Entrevista </a>en evento para <a target="_blank" rel="noreferrer"
                  href='https://www.fundaciononce.es/es'> Fundaci&oacute;n ONCE</a>
              </li>
            </ul>

          </div>
        </div>

        <div className=" container-xxl-1 row justify-content-center mx-5 mt-2">

          <div className="col-sm-5 me-5 sm-5 py-3">

            <p className="h2 bold display-5 text-secondary text-center">
              Hard Skills
            </p>

            <ul className="mt-5 mb-0 fst-italic text-left p-5 h4">
              <li>Spring <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={40} /></li>
              <li>Spring Security <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={10} /></li>
              <li>Spring Boot <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={30} /></li>
              <li>MVC <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={25} /></li>
              <li>Rest <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={25} /></li>
              <li>Maven <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={20} /></li>
              <li>React <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={60} /></li>
              <li>HTML 5 <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={45} /></li>
              <li>Bootstrap <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={40} /></li>
              <li>CSS <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={45} /></li>
              <li>SASS <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={25} /></li>
              <li>GIT <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={35} /></li>
              <li>MySQL <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={75} /></li>
              <li>SQL <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="primary" now={75} /></li>
            </ul>

          </div>

          <div className="col-sm-5 me-5 sm-5 py-3">
            <p className="h2 bold display-5 text-secondary justify-content-center text-center">
              Soft Skills
            </p>

            <ul className="justify-content-center mt-5 mb-0 fst-italic text-left p-5 h4">
              <li>Resilencia <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={80} /></li>
              <li>Trabajo en Equipo <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={90} /></li>
              <li>Resoluci&oacute;n <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={75} /></li>
              <li>Creatividad <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={80} /></li>
              <li>Dedicaci&oacute;n <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={85} /></li>
              <li>Responsabilidad <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={90} /></li>
              <li>Af&aacute;n de Superacion <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={100} /></li>
              <li>Puntualidad <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={100} /></li>
              <li>Tenacidad <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={100} /></li>
              <li>Prudente <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={75} /></li>
              <li>Honestidad <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={85} /></li>
              <li>Sinceridad <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={95} /></li>
              <li>Adaptabilidad <ProgressBar className="barraProgreso mt-2 mb-3" striped animated variant="success" now={75} /></li>
            </ul>

          </div>

        </div>

      </div>
    );
  }
}

export default OtrosDatosInteres;