import React from "react";
import { Button, Modal } from "react-bootstrap";
import ImagenBotonDiplomasPdf from "../multimedia/pdf.png";
import DiplomaPdf from './DiplomaPdf';


class ModalDiplomas extends React.Component {

    static imagen: any;
    static body: any;
    static titulo: any;
    static id: any;
    show: boolean;

    constructor(props: any) {
        super(props);
        this.show = false;
    }

    handleClose = () => {
        this.show = false;
        this.setState({ show: false });
    };

    handleShow = () => {
        this.show = true;
        this.setState({ show: true });
    };

    render(): React.ReactNode {
        return (
            <div>

                <Button
                    onClick={this.handleShow}
                    variant="btn-primary"
                >
                    <img src={ImagenBotonDiplomasPdf}
                        alt="boton abrir pdf" width={60} height={70} />
                </Button>

                <Modal size="lg" show={this.show} onHide={this.handleClose} animation={true}>
                    <Modal.Header closeButton className='bg-gradient'>
                        <Modal.Title><h3 className="h1 ms-5">Diplomas Curso Full Stack Pdf</h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='bg-gradient'>
                        <DiplomaPdf />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalDiplomas;